import { IOrder, ILabOrder } from '../types/order'
import { UserFileDto } from '../types/user'

import { get, post } from './api'
import { fetchUserToken } from './utils'

export const getLabOrders = (): Promise<IOrder[]> => get('/orders/lab')

export const addLabOrder = (order: ILabOrder): Promise<IOrder> => post('/orders/lab', order)

export const uploadTemperatureFile = (data: UserFileDto, orderId: number) => post(`/orders/lab/temperature/${orderId}`, data)

export const downloadFile = async (file: {
  key: string
  name: string
  type: string
}) => {
  const token = await fetchUserToken()
  const response = await fetch(`${process.env.REACT_APP_API_URL}/orders/lab/files/${file.key}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream',
      Authorization: token,
    },
  })
  const res = await response.blob()

  const url = window.URL.createObjectURL(
    new Blob([res], { type: file.type }),
  )

  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute('download', file.name)
  link.setAttribute('target', '_blank')
  document.body.appendChild(link)
  link.click()

  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)  
}
