import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'

import { Loader } from '../../shared/Loader'
import { uploadTemperatureFile } from '../../services/order'

export const UploadFile: React.FunctionComponent<{ orderId: number, onUpload: () => void, isOpen: boolean, onClose: () => void }> = ({
  orderId,
  onClose,
  isOpen,
  onUpload,
}) => {
  const [isUploading, setIsUploading] = React.useState(false)

  const toast = useToast()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles.slice(-1)[0]

      if (file.size > 5 * 1024 * 1024) {
        toast({
          title: 'Error uploading file',
          description: 'File is too large. The limit is 5mb',
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async () => {
        const base64 = reader.result?.toString()
        if (base64) {
          setIsUploading(true)
          try {
            await uploadTemperatureFile({
              file: base64,
              file_name: file.name,
              file_type: file.type,
              file_size: file.size,
            }, orderId)
            toast({
              title: 'File uploaded',
              description: 'Your file has been uploaded successfully',
              status: 'success',
              position: 'top',
              duration: 5000,
              isClosable: true,
            })
            onUpload()
          } catch (e: any) {
            let message =
              e?.response?.data?.message || e?.message || 'Something went wrong'
            if (message.includes('Network Error')) {
              message = 'File is too large. The limit is 5mb'
            }

            toast({
              title: 'Error uploading file',
              description: message,
              status: 'error',
              position: 'top',
              duration: 5000,
              isClosable: true,
            })
          }
          setIsUploading(false)
        }
      }
    },
    [orderId, onUpload, toast],
  )

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add temperature file to the order {orderId}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          { isUploading && <Loader /> }
          <UploadFileArea onDrop={onDrop} />
        </ModalBody>
      </ModalContent>
    </Modal>    
  )
}

const UploadFileArea: React.FunctionComponent<{
  onDrop: (acceptedFiles: File[]) => void
}> = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div
      className="flex items-center justify-center w-full relative"
      {...getRootProps()}>
      <div className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            aria-hidden="true"
            className="w-10 h-10 mb-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
          </svg>
          {isDragActive ? (
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Drop the files here ...
            </p>
          ) : (
            <>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                Please, upload a file smaller than 5MB
              </p>
            </>
          )}
        </div>
        <input {...getInputProps()} />
      </div>
    </div>
  )
}
