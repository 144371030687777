import { Hub } from 'aws-amplify'
import { HubCapsule, HubPayload } from '@aws-amplify/core'

import { createUser } from './user'

class EventsHub {
  constructor() {
    Hub.listen('auth', (data: HubCapsule) => this.onAuthEvent(data.payload))
  }

  onAuthEvent(payload: HubPayload) {
    if (payload.event === 'signIn') {
      createUser()
    }
  }
}

export default new EventsHub()
