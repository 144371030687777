import axios from 'axios'

import { fetchUserToken } from './utils'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const get = async (url: string) => {
  await setAuthToken()
  const response = await api.get(url)
  return response.data
}

export const post = async (url: string, data?: object) => {
  await setAuthToken()
  const response = await api.post(url, data)
  return response.data
}

export const put = async (url: string, data?: object) => {
  await setAuthToken()
  const response = await api.put(url, data)
  return response.data
}

export const del = async (url: string) => {
  await setAuthToken()
  const response = await api.delete(url)
  return response.data
}

export const setAuthToken = async () => {
  const token = await fetchUserToken()
  api.defaults.headers.common['Authorization'] = token
}
