import { HTMLAttributeAnchorTarget } from 'react'
import { Link } from 'react-router-dom'

export const Button: React.FunctionComponent<{
  children: React.ReactNode
  className?: string
  onClick?: () => void
  disabled?: boolean
  link?: boolean
  to?: string
  target?: HTMLAttributeAnchorTarget
  ref?: React.Ref<HTMLButtonElement>
}> = ({ children, onClick, disabled, className, ...rest }) => {
  const classes = `inline-block w-64 py-2 px-4 bg-primary text-white rounded-md shadow-md hover:bg-primary-300 hover:shadow-lg focus:bg-primary-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-900 active:shadow-lg transition duration-150 ease-in-out ${className}`

  if (rest.link) {
    return (
      <Link
        to={rest.to || ''}
        target={rest.target ? rest.target : '_self'}
        className={classes}>
        {children}
      </Link>
    )
  }

  return (
    <button className={classes} onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </button>
  )
}
