import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents'
import { useTheme, View } from '@aws-amplify/ui-react'

export const AuthComponents: DefaultComponents = {
  Header() {
    return (
      <div className="flex justify-center w-full">
        <img className="p-4 h-20" alt="Amplify logo" src="/assets/logo.svg" />
      </div>
    )
  },

  Footer() {
    const { tokens } = useTheme()

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div className="text-xs text-stone-500">&copy; All Rights Reserved</div>
      </View>
    )
  },
}
