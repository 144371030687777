import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { useEffect } from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useToast } from '@chakra-ui/react'

export const fetchUserToken = async function () {
  const user: CognitoUser = await Auth.currentAuthenticatedUser()

  try {
    const token = await new Promise<string>((res, rej) => {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            rej(err)
            return
          }
          if (session) {
            res(session.getIdToken().getJwtToken())
          }
          res('')
        },
      )
    })
    return token
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const useIsUserLabAdmin = () => {
  const { signOut } = useAuthenticator(context => [context.signOut])
  const toast = useToast()

  useEffect(() => {
    async function init() {
      const user: CognitoUser = await Auth.currentAuthenticatedUser()
      const payload = user.getSignInUserSession()?.getIdToken().payload

      if (!payload || !payload['cognito:groups'] || !Array.isArray(payload['cognito:groups']) || ! payload['cognito:groups'].includes('lab')) {
        toast({
          title: 'Unauthorized',
          description: 'You are not authorized to access this page.',
          status: 'error',
          duration: 5000,
        })
        signOut()
        return
      }      
    }
    init()
  }, [])

  return { }
}
