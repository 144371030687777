import {
  UserDto,
  IUserFiles,
  IUserSubscription,
  IUsersPostsampleCollectionData,
} from '../types/user'

import { api, get, post, setAuthToken } from './api'

export const getMe = (): Promise<UserDto> => get('/users')

export const getUser = (id: string): Promise<UserDto> => get(`/users/${id}`)

export const getUsersFiles = (): Promise<IUserFiles[]> => get(`/users-files`)

export const getUserSubscription = (): Promise<IUserSubscription> =>
  get(`/users/subscription`)

export const uploadUserFile = async (file: File) => {
  await setAuthToken()
  const formData = new FormData()
  formData.append('file', file)
  const response = await api.post(`/users-files`, formData)
  return response.data
}

export const createUser = () => post('/users')

export const updateUser = async (data: Partial<UserDto>) => {
  await setAuthToken()
  const response = await api.patch(`/users`, data)
  return response.data
}

export const getUserPostSampleCollectionData =
  (): Promise<IUsersPostsampleCollectionData> =>
    get('/typeform/post-sample-collection-data')
