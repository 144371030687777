export const AmplifyConfig = {
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
}

export const AmplifyLoginMechanisms: ('email' | 'username' | 'phone_number')[] =
  ['email']
