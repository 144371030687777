import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react'
import { Field, FieldProps } from 'formik'

export const FieldItem: React.FunctionComponent<{
  name: string
  label: string
  disabled?: boolean
  type?: string
}> = ({ name, label, disabled, type = 'text' }) => (
  <div className="inline-block mt-6 w-full">
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
          isDisabled={disabled}>
          <div className="text-sm font-semibold mb-1">{label}</div>
          <Input
            {...field}
            placeholder={label}
            type={type}
            value={field.value || ''}
          />
          <FormErrorMessage>{form.errors[name] as string}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  </div>
)
