import * as React from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { MdGppGood, MdWarning } from 'react-icons/md'
import { Icon } from '@chakra-ui/react'

import { IOrder } from '../../types/order'
import { Button } from '../../shared/UI'
import { downloadFile } from '../../services/order'

export const Table: React.FunctionComponent<{
  data: IOrder[],
  onAddFile: (id: number) => void
}> = ({ data, onAddFile }) => {
  const columns = React.useMemo<GridColDef[]>(
    () => [
      { field: 'lab_id', headerName: 'ID', width: 70 },
      { field: 'barcode', headerName: 'Barcode', width: 120 },
      { field: 'tracking_number_to_lab', headerName: 'Tracking Number', width: 200 },
      {
        field: 'add_file',
        headerName: 'Temperature File',
        sortable: false,
        width: 180,
        renderCell: ({ row }) => {
          if (row.temperature_file) {
            return (
              <Button
                className="w-auto"
                onClick={() => {
                  downloadFile(row.temperature_file)
                }}
              >
                Download
              </Button>
            )
          }

          return (<Button
          className="w-auto"
          onClick={() => {
            onAddFile(row.id)
          }}>
          Add file
        </Button>
      )
        },
      },
      {
        field: 'info',
        headerName: 'Matched',
        sortable: false,
        width: 180,
        renderCell: ({ row }) => {
          if (row.tasso_id) {
            return (
              <Icon color="green" boxSize={28}>
                <MdGppGood />
              </Icon>
            )
          }

          return (
            <Icon
              color={'red'}
              boxSize={28}>
              <MdWarning  />
            </Icon>
          )
        },
      },
    ],
    [],
  )
  const pageSize = 50
  const height = pageSize * 54 + 15

  return (
    <div style={{ height: height + 'px', width: '100%' }}>
      <ThemeProvider theme={createTheme()}>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            disableSelectionOnClick
          />
        </StyledEngineProvider>
      </ThemeProvider>
    </div>
  )
}
