export const Page = ({
  children,
  header,
}: {
  children: React.ReactNode
  header: React.ReactNode
}) => {
  return (
    <div className="w-full sm:pl-6 sm:pr-16 px-6 mt-4 overflow-x-hidden">
      {header}
      <div className="my-2 w-full h-[1px] bg-black opacity-10" />

      {/* Content */}
      <div className="flex flex-col w-full min-h-[calc(100vh-400px)]">
        {children}
      </div>

      {/* Footer */}
      <div className="py-20 text-neutral-400 text-[11px]">
        iollo is for anyone who wants to be empowered with information and data
        about their blood metabolome. iollo tests are intended exclusively for
        wellness purposes. iollo cannot provide you with medical advice or
        diagnose you with any disease or condition. Any information provided by
        iollo is not medical advice and is not intended to replace the advice of
        your medical professional.
      </div>
    </div>
  )
}
