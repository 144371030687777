import { useCallback } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { useMutation } from '@tanstack/react-query'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'

import { Loader } from '../../shared/Loader'
import { FieldItem } from '../../shared/UI/FieldItem'
import { addLabOrder } from '../../services/order'
import { ILabOrder } from '../../types/order'

export const AddOrder: React.FunctionComponent<{
  onCreate: () => void
  isOpen: boolean
  onClose: () => void
}> = ({ onCreate, isOpen, onClose }) => {
  const { mutate } = useMutation(['addLabOrder'], addLabOrder)
  const toast = useToast()

  const onCreateOrder = useCallback(
    async (values: ILabOrder, actions: FormikHelpers<ILabOrder>) => {
      await mutate(values, {
        onSuccess: () => {
          toast({
            title: 'Order record added',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          actions.resetForm()
          onCreate()
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: (data: any) => {
          console.log(data?.response?.data)
          toast({
            title: 'Order with this barcode or tracking number is not found',
            description: 'Data will not be saved',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
      })
      actions.setSubmitting(false)
    },
    [mutate],
  )

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={'2xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Order Record</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <CreateOrderForm onSubmit={onCreateOrder} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

const CreateOrderForm: React.FunctionComponent<{
  onSubmit: (
    values: ILabOrder,
    actions: FormikHelpers<ILabOrder>,
  ) => void
}> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={defaultOrder}
      onSubmit={onSubmit}
      validate={values => {
        const errors: Partial<ILabOrder> = {}
        Object.keys(values).forEach(key => {
          if (!values[key as keyof typeof defaultOrder]) {
            errors[key as keyof typeof errors] = 'Required'
          }
        })
        return errors
      }}>
      {props => (
        <Form className="w-full sm:max-w-2xl">
          <div className="flex flex-col w-full">
            <FieldItem name="id" label="ID" />
            <FieldItem name="barcode" label="Barcode" />
            <FieldItem name="tracking_number" label="Tracking Number" />
          </div>

          <button
            className="inline-block w-64 mt-8 py-2 px-4 bg-primary text-white rounded-md shadow-md hover:bg-primary-300 hover:shadow-lg focus:bg-primary-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-900 active:shadow-lg transition duration-150 ease-in-out"
            type="submit">
            {props.isSubmitting ? (
              <Loader theme="white" height={24} />
            ) : (
              'Add Record'
            )}
          </button>
        </Form>
      )}
    </Formik>
  )
}

const defaultOrder: ILabOrder = {
  id: '',
  tracking_number: '',
  barcode: '',
}
