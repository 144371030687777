import { useDisclosure } from '@chakra-ui/react'
import React, { useCallback } from 'react'

import { Button } from '../../shared/UI'
import { IOrder } from '../../types/order'

import { AddOrder } from './AddOrder'
import { Table } from './Table'
import { UploadFile } from './UploadFile'

export const Content: React.FunctionComponent<{
  data: IOrder[]
  refetch: () => void
}> = ({ data, refetch }) => {
  const [orderId, setOrderId] = React.useState<number | null>(null)
  const {
    isOpen,
    onOpen,
    onClose,
  } = useDisclosure()
  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure()

  const onAddFile = useCallback((id: number) => {
    setOrderId(id)
    onOpenUpload()
  }, [data, setOrderId])

  return (
    <div className="flex flex-col">
      <Button onClick={onOpen} className="my-8">Add Order Record</Button>
      <Table data={data} onAddFile={onAddFile} />

      <AddOrder
        isOpen={isOpen}
        onClose={onClose}
        onCreate={refetch}
      />
      <UploadFile
        orderId={orderId || 1}
        isOpen={isOpenUpload}
        onClose={onCloseUpload}
        onUpload={refetch}
      />
    </div>
  )
}
