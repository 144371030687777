import { createBrowserRouter } from 'react-router-dom'

import { Orders } from '../scenes/Orders/Orders'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Orders />
  },
])
