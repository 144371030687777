import { useQuery } from '@tanstack/react-query'

import { getLabOrders } from '../../services/order'
import { useIsUserLabAdmin } from '../../services/utils'
import { Loader } from '../../shared/Loader'
import { Page } from '../../shared/Page'

import { Content } from './Content'

export const Orders = () => {
  useIsUserLabAdmin()
  const {
    isLoading,
    isRefetching,
    data,
    refetch,
  } = useQuery({
    queryKey: ['getLabOrders'],
    queryFn: getLabOrders,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

  if (!isLoading && !data)
    return <Page header="Orders">No orders found</Page>

  return (
    <Page
      header={<h1 className="font-bold text-2xl m-0 p-0">Orders</h1>}>
      {isLoading || isRefetching ? (
        <Loader />
      ) : (
        <Content data={data} refetch={refetch} />
      )}
    </Page>
  )
}
