import { RouterProvider } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { ChakraProvider } from '@chakra-ui/react'

import { AmplifyConfig, AmplifyLoginMechanisms } from './configs/amplify'
import { AuthComponents } from './shared/Auth'
import { router } from './configs/router'
import theme from './configs/theme'
import './services/hub'

Amplify.configure(AmplifyConfig)

const queryClient = new QueryClient()

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Authenticator
          loginMechanisms={AmplifyLoginMechanisms}
          components={AuthComponents}
        >
          <RouterProvider router={router} />
        </Authenticator>
      </QueryClientProvider>
    </ChakraProvider>
  )
}
